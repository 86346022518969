import "./style.css";
import logo from "./logo.svg";
import home from "./home.svg";
import { Context } from "../Context";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { Voice } from "../Voice";
export default function Navbar() {
  const [voice, setVoice] = useContext(Voice);
  const [inputVoice, setInputVoice] = useState("");
  const [outputVoice, setOutputVoice] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    if (voice.includes("найди услугу")) {
      Navigate(routes.services);
    }
    if (voice.includes("врач")) {
      Navigate(routes.doctors);
    }
    if (voice.includes("акци")) {
      Navigate(routes.actions);
    }
  }, [voice]);
  function listen() {
    let recognition = new window.webkitSpeechRecognition();
    recognition.lang = "ru-RU";
    recognition.interimResults = true;
    recognition.start();

    recognition.onresult = function (event) {
      setVoice(event.results[0][0].transcript);
    };
  }
  return (
    <div className="navbar">
      <img
        src={logo}
        style={{ cursor: "pointer" }}
        alt=""
        onClick={() => Navigate(routes.main)}
      />
      <div className="home" onClick={() => Navigate(routes.main)}>
        <img src={home} alt="" />
        <p>Главная</p>
      </div>
      <ul className="home_links">
        {localStorage.type == "stom" && (
          <li onClick={() => Navigate(routes.actions)}>
            <div className="circle"></div>
            <p>
              {localStorage.tel == "+7 (980) 800 8080" ? "Отзывы" : "Акции"}
            </p>
          </li>
        )}
        {localStorage.type == "stom" && (
          <li onClick={() => Navigate(routes.doctors)}>
            <div className="circle"></div>
            <p>
              {localStorage.tel == "+7 (980) 800 8080" ? "Услуги" : "Врачи"}
            </p>
          </li>
        )}
        {localStorage.type == "Shoes" && localStorage.id != "26" && (
          <li onClick={() => Navigate(routes.orders)}>
            <div className="circle"></div>
            <p>Заявки</p>
          </li>
        )}
        {localStorage.type == "Shoes" && localStorage.id != "26" && (
          <li onClick={() => Navigate(routes.tovar)}>
            <div className="circle"></div>
            <p>Товары</p>
          </li>
        )}
        {localStorage.type == "Shoes" && (
          <li onClick={() => Navigate(routes.usersBali)}>
            <div className="circle"></div>
            <p>Пользователи</p>
          </li>
        )}
        {/*   {localStorage.type == "Shoes" && (
          <li onClick={() => Navigate(routes.MainPageShop)}>
            <div className="circle"></div>
            <p>Страницы</p>
          </li>
        )} */}
        {localStorage.type == "Shoes" && localStorage.id == "26" && (
          <li onClick={() => Navigate("/elements")}>
            <div className="circle"></div>
            <p>Доп. материалы</p>
          </li>
        )}
        {localStorage.type == "Shoes" && localStorage.id == "26" && (
          <li onClick={() => Navigate("/tovar2")}>
            <div className="circle"></div>
            <p>Объекты</p>
          </li>
        )}
        {localStorage.type == "stom" && (
          <li onClick={() => Navigate(routes.services)}>
            <div className="circle"></div>
            <p>{localStorage.tel == "+7 (980) 800 8080" ? "FAQ" : "Услуги"}</p>
          </li>
        )}
        {localStorage.type == "object" && (
          <li onClick={() => Navigate(routes.objects)}>
            <div className="circle"></div>
            <p>Обьекты</p>
          </li>
        )}
        {localStorage.type == "object" && (
          <li onClick={() => Navigate(routes.razdel)}>
            <div className="circle"></div>
            <p>Разделы</p>
          </li>
        )}
        {localStorage.id != "26" && (
          <li onClick={() => Navigate(routes.clients)}>
            <div className="circle"></div>
            <p>Пользователи</p>
          </li>
        )}
        {localStorage.type == "taro" && (
          <li onClick={() => Navigate(routes.dop)}>
            <div className="circle"></div>
            <p>Доп материалы</p>
          </li>
        )}
        {localStorage.type == "taro" && (
          <li onClick={() => Navigate(routes.courses)}>
            <div className="circle"></div>
            <p>Обучающие курсы</p>
          </li>
        )}
        {localStorage.type == "taro" && (
          <li onClick={() => Navigate("/zadanie")}>
            <div className="circle"></div>
            <p>Задания</p>
          </li>
        )}
        {localStorage.type == "taro" && (
          <li onClick={() => Navigate("/reviews")}>
            <div className="circle"></div>
            <p>Отзывы</p>
          </li>
        )}
        <li className="voice_assistant">
          <h3>Голосовой помощник</h3>

          <div className="listen" onClick={() => listen()}>
            Слушать
          </div>
        </li>
      </ul>
    </div>
  );
}
